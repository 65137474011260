
import NavBar from '@/components/structure/NavBar.vue'
// import Footer from '@/components/structure/Footer.vue'

export default {
  name: 'ErrorPage',
  components: {
    NavBar,
    // Footer,
    ErrorMessage: () => import('~/components/structure/ErrorMessage.vue'),
  },
  layout: 'error-layout',

  data() {
    return {
      error: {
        statusCode: 404,
        message: 'Not found',
      },
    }
  },
}
